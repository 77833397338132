import React from "react"

import ContentPage from "../../components/contentPage"
import { Link } from "gatsby";
import styles from "./index.module.css";

const Privacy = () => (
  <>
    <ContentPage title={'Privacy Policy'} isSmallFonts={true}>
        <p>MProfit Software Private Limited (“Company,” “MProfit”, “we”, “us”, or “our”) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the personal information we collect, use, and share in connection with the Company website, mobile app, and other software provided on or in connection with our services, as further described in our Terms of Use available at <TermsOfUseLink text={'www.mprofit.in/terms-of-use'}/> (collectively, the “Services”).</p>
        <p>By using the Services, application, website or any of our offerings, you confirm that you understand and consent to this Privacy Policy, together with any documents that may be expressly referred to and are incorporated by reference. You will be deemed to be a “user” who is legally bound by this Policy. </p>
        <p>If you use the Services on behalf of an entity, customers or family members, you represent that you are authorized by such party to accept this Privacy Policy on such party’s behalf, and consent on behalf of such party to our collection, use and disclosure of such party’s information as described in this Privacy Policy.</p>
        <p>At MProfit Software Private Limited, we are committed to protecting your privacy and ensuring that your personal data is handled in compliance with the Information Technology Act, 2000 (as amended from time to time) and General Data Protection Regulation (GDPR) (EU) regulations. This Privacy Policy explains how we collect, use, and share your personal data. By using our services, you consent to the practices described in this policy.</p>

        <h4>COLLECTION OF INFORMATION</h4>

        <p>As a visitor, you can browse our Platform (as defined in our Terms of Use available at <TermsOfUseLink text={'www.mprofit.in/terms-of-use'}/>) to explore more about us without providing any of your personal information.</p>

        <p>Once you register as a user on our Platform or show interest in procuring information about our Services, we may collect the following information from you and such information may include but not be limited to:</p>

        <ol type="a">
            <li>Your name, e-mail address, phone number, username, password, billing address, GST Number;</li>
            <li>Information provided to us through our Services, interaction with our customer service, participation in surveys, marketing promotions and user attributes; and </li>
            <li>Account settings and preferences</li>
        </ol>

        <p>Do note that the above information that is collected is essential for Us to provide you the services and we do not sell or rent your personal information to anyone, for any reason, at any time. We only collect and use such information from you that we consider necessary for achieving an efficient and safe experience that is customized to your needs and expectations which in turn helps us to offer Services that are most likely to meet your needs and demands.</p>

        <p>We automatically collect some non-personal information like computer, device and browsing information, I.P address, operating system of your device, browser type, URLs, domain names, platform types, number of clicks and other similar information when you access our Platform or as a result of your interaction with the Services.</p>

        <p>We and our authorized business partners may also collect information made publicly available through other third-party platforms, through online or offline databases that are otherwise legitimately obtained and are subject to the third party's terms of use and/or privacy statement.</p>

        <p>In addition, please do note that when carrying out any financial transaction, you may be diverted to a third-party payment gateway, and such third-party service provider may collect information such as your banking details and billing information including but not limited to Goods and Service Tax number, Permanent Account Number, debit card number, credit card number etc. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this Privacy Policy. You should exercise caution and look at the privacy statement applicable to the website of such third-party service provider in question.</p>

        <p>Company shall use technologies such as cookies, clear gifs, log files, and flash cookies for several purposes, including to help understand how you interact with our Platforms and Services to provide a better experience.   </p>

        <p>Kindly note that MProfit shall not be liable or responsible for any data, documents or other information that You may share with MProfit in any manner. MProfit will not be able to keep track of any information received or communication exchanged through emails, messages, post or any other manner and shall not be liable for the same. </p>


        <h4>DATA USAGE</h4>

        <p>We process your personal information to run our business, provide the Services, personalize your experience on the Services and improve the Services from time to time. In addition, we may use your personal information:</p>

        <ol type="a">
          <li>To verify your identity;</li>
          <li>To enhance your experience, analyse your interaction with the Services and issue any corrective measures if required;</li>
          <li>For any legal and regulatory/audit purposes;</li>
          <li>To respond to your queries about the Services;</li>
          <li>To deliver to you any notices, alerts, or communications relevant to your use of the Services;</li>
          <li>To customise/personalise the Services being offered;</li>
          <li>To aggregate anonymised information from registered users to perform market research, conduct project planning & product development, generate marketing insights, analyse user behaviour, troubleshoot problems;</li>
          <li>For internal record-keeping purposes; and</li>
          <li>To enforce this Policy and/or any terms thereof.</li>
        </ol>

        <p>As a minor if you wish to use our products or Services, such use shall be made available to you by your legal guardian or parent, who has agreed to these terms. The Company will not be responsible for any consequence that arises as a result of misuse of any kind of our Platform or any of our products or Services that may occur by virtue of any person including a minor registering for the Services/products provided. Any portion of the information containing personal data relating to minors provided by you shall be deemed to be given with the consent of the minor's legal guardian. </p>

        <p>We identify and use your IP address to help diagnose problems with our server and to administer our website and application. We further use your IP address to protect ourselves from fraud and to help identify you and to gather broad demographic information.</p>

        <h4>DATA RETENTION</h4>

        <p>We will retain your personal data only for as long as necessary to fulfil our purposes provided herein or for quality assurance purposes, or to defend future legal claims, unless the law requires a longer period of retention.</p>

        <h4>COOKIES</h4>

        <p>A cookie is a very small text document, which often includes an anonymous unique identifier. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. We use cookies on our Platform which help us to provide information which is targeted to your interests, keep track of your preferences, evaluate the effectiveness of our Platform, analyse trends, identify logged in or registered users and to administer the Platform.</p>

        <p>We further use cookies to determine which parts of our Platform are most visited and what difficulties our visitors may experience in accessing our Platform. </p>

        <p>Your use of our Platform or Services with a browser that is configured to accept cookies constitutes acceptance of ours and any third-party cookies.</p>

        <h4>DATA PROTECTION AND SECURITY </h4>

        <p>We employ many different technological and procedural security measures like encryption, passwords, as well as some physical security measures, which are reasonably designed to help protect your personal information from and against any loss or misuse and unauthorized access or disclosure by individuals inside and outside the company. However, no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. You therefore agree that any security breaches beyond the control of our reasonable security procedures are at your sole risk. The Company is not responsible for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for any activity on your account via unauthorized password activity.</p>

        <h4>DISCLOSURE OF PERSONAL INFORMATION</h4>

        <p>We use other companies, agents, or contractors ("Service Providers") to perform services on our behalf or to assist us with the provision of Services to you. We engage Service Providers to provide marketing, advertising, communications, infrastructure, and IT services, to personalize and optimize our Services, to process credit card transactions or other payment methods, to provide customer service, to collect debts, to analyse user data (including data about users’ interactions with our Service) to enhance the user experience, and to process and administer consumer surveys. In the course of providing such services, these Service Providers may have access to your personal information. We do not authorize them to use or disclose your personal information except in connection with providing their services.</p>

        <p>We may disclose your personal information if we believe that such action is necessary. Following are some of the ways that your personal information may be disclosed:</p>

        <ol type="a">
            <li><strong>Legal obligation:</strong> We can disclose any personal information provided by you to law enforcement and other government officials as we believe appropriate or necessary to comply with our legal obligations or for investigation of fraud or any other activity which may expose us or you to legal liability.</li>
            <li><strong>Information disclosed to third party service providers:</strong> We may contract with various third parties for the provision and maintenance of our Platform, Services and our business operations, and we may need to share your personal information with these vendors and service agencies. We do not authorize them to use or disclose your personal information except in connection with providing their services. We shall not be liable for any breaches by any third parties or service providers. </li>
            <li><strong>Information shared with business partners:</strong> If, at any time, you choose to utilise the online or offline services of business partners through us, we will share your information with these business partners with your prior consent.</li>
            <li><strong>Links to external service providers:</strong> Our Platform may contain hyperlinks to other platforms which may collect personally identifiable information about you. We have no control over any websites or resources which are provided by companies or persons other than us and are not responsible for the privacy practices or the content of those external platforms and your use of any such other website or application is subject to the terms of use and privacy policy located on such external website or application. We recommend that you check the external platform’s privacy policies to see how the operators of such websites will utilize your personal information.</li>
            <li><strong>Internal departments:</strong> We may share personal information with the internal investigation department of the Company or agencies appointed by us for investigation purposes. </li>
            <li><strong>Participation in MProfit’s Referral Program:</strong> If you sign up for MProfit by using a referral link of an existing MProfit customer, we reserve the right to share your email address, sign-up date and subscription date with the referring customer. The purpose of this exercise is to provide the necessary details to MProfit customers about their referrals and the corresponding benefits they would receive for making such referrals in line with MProfit’s referral policy that is covered under our Terms of Use.</li>
        </ol>

        <h4>YOUR CONSENT</h4>

        <p>By using our Platform, you are expressly consenting to our collection, processing, storing, disclosing and handling of your information as set forth in this Policy now and as amended by us from time to time. Your information will be transferred, processed and stored in accordance with the reasonable technological security measures and procedures in accordance with the Information Technology Act 2000 and rules thereunder and, to the extent applicable to you if you reside outside India, in compliance with the EU General Data Protection Regulation (GDPR). If You object to Your Information being transferred or used in this way, please do not provide the details of your information on the Platform.</p>

        <h4>YOUR RIGHTS IN RELATION TO PERSONAL INFORMATION COLLECTED BY US</h4>

        <p>If you choose to withdraw your consent at any time, you will be required to delete your account on our Platform. Steps for deletion of a user’s account have been provided in the Platform. Deletion of your account shall automatically delete all your data. We shall not retain any data you may have shared with us through the Platform upon deletion of your account. Please note that withdrawal of consent will not be retrospective in nature and shall be applicable prospectively.</p>

        <p>If you require deletion of any data or information that has been communicated with MProfit other than through the Platform, you will be required to, in addition to the steps provided above, send an email to us at support@mprofit.in stating your request for deletion of such additional data or information. MProfit shall upon execution of your request, send you a confirmation that such data/ information has been deleted within a reasonable time period. </p>

        <p>If a user or customer makes a request to access, review, modify or correct any personal information, the Company will consider each such request in accordance with the applicable data protection laws and regulations. </p>

        <p>However, you agree and acknowledge that your right to access, modify, delete data and/or withdraw your consent to provide personal information as mentioned above may be denied or limited by us, as may be required under any applicable law, law enforcement requests or under any judicial proceedings.</p>

        <h4>MARKETING AND PROMOTION</h4>

        <p>We may send periodic promotional or informational emails to you. You may opt to discontinue such communications by writing to us via email at support@mprofit.in.</p>

        <p>Additionally, if you share your phone number, we may contact you through phone calls, SMS or WhatsApp messages to inform you about the latest offerings on our Platform or to contact you regarding your account and interaction with the Platform. You may opt to discontinue such communications by writing to us via email at support@mprofit.in.</p>

        <p>We may require reasonable amount of time to process any opt-out requests. If you opt-out of receiving emails about recommendations we think may interest you, we may still send emails on a case-to-case basis. </p>

        <h4>PRIVACY POLICY UPDATES</h4>

        <p>This policy is current as of the effective date set forth at the bottom of this page. Company may in its sole and absolute discretion modify the policy at any time and in any manner without any prior notification to you. Any change or revision will be posted on our Platforms. Thus, you are advised to review this page periodically for any changes.</p>

        <h4>DISCLAIMER</h4>

        <p>We understand the highly sensitive nature of your personal information and we shall protect the same in accordance with our policy and to the best of our ability.</p>

        <p>The Company does not warrant or assure that the information provided on the Company website to be accurate and error-free. In case of any discrepancies, users are advised to verify the information by getting in touch with us. Information provided on our website might get updated or changed from time to time and in such cases, it shall be the duty of the user to verify the contents of the website. </p>

        <h4>QUESTIONS AND SUGGESTIONS AND GRIEVANCE OFFICER</h4>

        <p>If you have any questions or comments about the Privacy Policy, please write to our Grievance Officer at support@mprofit.in.</p>

        <p class={styles.lastUpdate}>Date of last update: 12th December 2023</p>

    </ContentPage>
  </>
)

const TermsOfUseLink = ({text}) => <Link className={styles.emphasizedLink} to="/terms-of-use">{text}</Link>

export default Privacy
