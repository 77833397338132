import React from "react"

import Layout from "../components/flayout"
import PrivacyPolicy from "../components/privacyPolicy"

const Page = () => (
  <Layout darkHeader={true} seoFields={seoFields} showAnnouncement={false}>
    <PrivacyPolicy />
  </Layout>
)

const seoFields = {
  description: "Read MProfit's privacy policy.",
  // lang,
  // meta,
  // keywords,
  title: "Privacy Policy" 
}

export default Page
